import React from "react";
import { Layout } from "../components/Layout";
import SEO from "../components/seo";

const IndexPage = () => (
    <Layout>
        <SEO title="Home" />
        <p>Hi! I’m Jules.</p>
        <p>
            When I'll find some time, I'll actually make a site out of this...
            something.
        </p>
        <p>
            I'm a software engineer at {" "}
            <a
                href="https://seccl.tech/"
                target="_blank"
                rel="noopener noreferrer"
            >
                Seccl.
            </a>
        </p>
        <p>
            To find out more about me and for more up to date information please
            visit my LinkedIn profile on the following link:{" "}
            <a
                href="https://www.linkedin.com/in/gyulasoos"
                target="_blank"
                rel="noopener noreferrer"
            >
                LinkedIn profile page
            </a>
        </p>
    </Layout>
);

export default IndexPage;
